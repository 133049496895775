<template>
    <div id="pageNotFound">
        <PageTitle title="PAGE NOT FOUND"/>
        <div>
            <p v-html="content">
            </p>
            <hr>
            <router-link to="/" id="goHome">
                <button>HOMEPAGE</button>
            </router-link>
        </div>
    </div>
</template>

<script>
import PageTitle from "../components/PageTitle"

export default {
    name: "PageNotFound",
    components: {
        PageTitle
    },
    data(){
        return{
            content: "Sorry, the page you're looking for Couldn't be located.<br>Please return to the homepage.",
        }
    },
    metaInfo: {
        title: 'Page Nout Found',
        titleTemplate: '%s | Paul Salazar',
        meta: [{
            name: 'description', 
            content: "Paul Salazar"
        }]
    },
}
</script>

<style lang="scss" scoped>
#pageNotFound{
    text-align: center;
}
p{
    margin-top: 15%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    line-height: 2.5rem;
    color: #000;
}
hr{
    border:0;
    height: 2px;
    color: #000;
    background-color: #000;
    width: 3rem;
    margin-bottom: 2rem;
}

#goHome{
    button{
        color: #fff;
        font-size: 1rem;
        letter-spacing: 3px;
        background: #000;
        padding: 0.5rem 2rem;
        margin-bottom: 20%;
        font-weight: 600;
        &:hover{
            cursor: pointer;
        }
    }
}
</style>